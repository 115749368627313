// @flow

const JSONMixin = {
  /**
   * Get a json serializable value. This basically calls JSON.parse on the returned value.
   * @param key: Item key to get (string).
   * @return: Stored value, or undefined if not set / expired.
   * */
  getJson<T>(key: string): null | T | any {
    // get value
    // $FlowFixMe[object-this-reference]
    const val = this.getItem(key)

    // if null, return null
    if (val === null || val === undefined) {
      return null
    }

    try {
      return JSON.parse(val)
    } catch (error) {
      console.error(error, { key })
      // $FlowFixMe[object-this-reference]
      this.removeItem(key)

      return {}
    }
  },

  /**
   * Set a json serializable value. This basically calls JSON.stringify on 'val' before setting it.
   * @param key: Item key to set (string).
   * @param value: Value to store (object, will be stringified).
   * @param expiration: Expiration time, in seconds. If not provided, will not set expiration time.
   * @param return: Storage.setItem() return code.
   * */
  setJson(key: string, val: any, expiration: ?number): ?string {
    // special case - make sure not undefined, because it would just write "undefined" and crash on reading.
    if (val === undefined) {
      throw new Error('Cannot set undefined value as JSON!')
    }

    // set stringified value
    // $FlowFixMe[object-this-reference]
    return this.setItem(key, JSON.stringify(val), expiration)
  },
}

export { JSONMixin }
