// @flow

import { LEAD_REQUEST_TYPE } from 'constants/index'
import { FoxfordService } from 'services/foxford'

import { addAnalytic } from './analytics'
import { showPhoneError } from './phone-mask/index'

import type { LeadRequestData, UserEvent, LeadRequestResponse } from '@foxford/foxford-js-sdk'

type PushEvent = [string, string]

type sendLeadRequestProps = {
  context: string,
  lrData: LeadRequestData,
  analyticTrigger?: string,
  pushEvent?: PushEvent,
}

/**
 * Осуществляет отправку лид-реквеста и аналитику характерную для него, если передана
 */
export const sendLeadRequest = async ({
  context,
  lrData,
  analyticTrigger = '',
  pushEvent = ['', ''],
}: sendLeadRequestProps): Promise<LeadRequestResponse> => {
  const [pushEventAction, pushEventLabel] = pushEvent
  // const search = window.location.search.replace(new RegExp('[?&]type=[^&#]*(#.*)?$'), '$1')
  const leadRequest = FoxfordService.leadrequest.createLeadRequest(LEAD_REQUEST_TYPE, context, lrData)

  const data = await FoxfordService.sendLeadRequest(leadRequest)

  if (analyticTrigger) {
    addAnalytic(analyticTrigger)
  }

  const allowedActions = ['click', 'conversion', 'experiment', 'funnel']

  if (allowedActions.includes(pushEventAction) && pushEventLabel) {
    const action: $PropertyType<UserEvent, 'action'> = (pushEventAction: any)
    await FoxfordService.user.pushEvent({ action, label: pushEventLabel })
  }

  return data
}

/**
 * Стандартный обработчик ошибок при отправке лид-реквеста
 */
export const leadRequestErrorHandler = (
  $form: JQuery,
  errors?: Object,
  phoneInputSelector: string = 'input[data-name=Phone]'
) => {
  const delay = 800

  setTimeout(() => {
    $form.show().siblings('.w-form-done').hide()
  }, delay)

  if (errors) {
    setTimeout(() => {
      const input = $form.find(phoneInputSelector)
      showPhoneError(input)
    }, delay)
  }
}
