// @flow

import { HOST } from 'constants/index'
import { FoxfordService } from 'services/foxford/index'

import type { User } from '@foxford/foxford-js-sdk'

/**
 * Редирект по ссылке после авторизации/регистрации пользователя
 * @param {string} [url] ссылка куда редиректим после авторизации
 */
export const redirectAfterLogin = (url: string) => {
  window.location.assign(`${HOST}/user/login?redirect_url=${url}`)
}

/**
 * Возвращаем обратно на страницу после авторизации/регистрации
 * @param {string} [path] Относительный путь куда редиректим после логина
 */
export const redirectBack = (path?: string) => {
  window.location.assign(`${HOST}/user/login?redirect_url=${window.location.origin}${path || window.location.pathname}`)
}

// В корзину
export const redirectToCheckout = () => {
  window.location.assign(`${HOST}/checkout`)
}

// В дашборд
export const redirectToDashboard = () => {
  window.location.assign(`${HOST}/dashboard`)
}

// Редирект в выбранный курс
export const redirectToCourse = (id: string | number) => {
  window.location.assign(`${HOST}/courses/${id}`)
}

// Редирект пользователя в олимпиаду после авторизации/регистрации
export const redirectToOlympiads = (id: string | number) => {
  const user: User = FoxfordService.user.getCurrentUser()

  if (!user.isLoggedIn()) {
    window.location.assign(`${HOST}/user/login?redirect_url=${HOST}/olympiads/${id}`)
    return
  }

  window.location.assign(`${HOST}/olympiads/${id}`)
}

// Добавление курса в корзину и редирект ту да же
export const addAndRedirectToCheckout = (id: string | number) => {
  window.location.assign(`${HOST}/courses/${id}/buy`)
}

// Запись на курс (его бесплатную часть) и редирект на страницу курса
export const enrollAndRedirectToCourse = (id: string | number) => {
  window.location.assign(`${HOST}/courses/${id}/apply`)
}
// Запись на олимпиаду и редирект на страницу олимпиады
export const enrollAndRedirectToOlympiads = (id: string | number) => {
  window.location.assign(`${HOST}/olympiads/${id}/apply`)
}

/**
 * Запись на указанные продукты (курс, продакт пак) с помощью промокода
 * @param {string} promocode Промокод.
 * @param {number[]} ids ID продуктов, которые требуется активировать.
 */

export const enrollCourseWithPromo = (promocode: string, ids: number[]) => {
  window.location.assign(`${HOST}/courses/promo/${promocode}/activate?course_ids=${ids.toString()}`)
}

export const enrollProductPacksWithPromo = (promocode: string, ids: number[]) => {
  window.location.assign(`${HOST}/product-packs/promo/${promocode}/activate?product_pack_ids=${ids.toString()}`)
}
