// @flow

import $ from 'jquery'

import { submitForm } from 'common/form'
import { ModalWindow } from 'common/modals'
import { initPhoneMaskModule } from 'common/phone-mask/index'
import { FsdvSlider } from 'common/slider-feedback'

import { changeTabsMarathonIt } from './action/change-tabs'

const SLIDER_CONFIG_1 = {
  selectors: {
    leftArrow: '[data-slider-1="arrow-left"]',
    rightArrow: '[data-slider-1="arrow-right"]',
    mask: '[data-slider-1="mask"]',
    slide: '[data-slider-1="item"]',
    stateDisable: 'disabled',
  },
  remainingSlides: 3,
}

export default () => {
  new ModalWindow()
  new FsdvSlider('[data-slider="junior"]', SLIDER_CONFIG_1)

  $('[data-tab]').on('click', ({ currentTarget }) => {
    changeTabsMarathonIt($(currentTarget))
  })

  initPhoneMaskModule()

  $('form[data-name="callback"]').submit(async (event) => {
    await submitForm(event)
  })

  $('form[data-name="enroll"]').submit(async (event) => {
    await submitForm(event)

    const id = $(event.currentTarget).attr('data-id')
    window.location.assign(`https://foxford.ru/courses/promo/DIGITAL14/activate?course_ids=${id}`)
  })
}
