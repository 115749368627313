// @flow

import $ from 'jquery'

import { leadRequestErrorHandler, sendLeadRequest } from 'common/send-lead-request'
import { GRADE_INDEX_ID_MAP } from 'constants/foxford'

import { FoxfordService } from '../services/foxford'

import type { LeadRequestData, LeadRequestResponse } from '@foxford/foxford-js-sdk'

/**
 * Возвращает LeadRequestData из данных переданной формы.
 * @param {JQuery} $form Форма.
 */
export const getLeadRequestData = (event: Event): LeadRequestData => {
  const $form = $(event.currentTarget)

  const disciplineId = Number($form.find('[data-name="Discipline"], [data-name="discipline"]').val()) || undefined
  const gradeIndex = String(
    $form.find('select[data-name="Grade"], select[data-name="grade"]').val() || $form.attr('grade-id')
  )
  const subscriptionExternship = $form.attr('subscription_externship') || $form.attr('subscription-externship')
  let email: any = $form.find('input[data-name="Email"], input[data-name="email"]').val()
  if (email) {
    email = String(email)
  }

  return {
    agreement: $form
      .find(
        'input[data-name="Agreement"], input[data-name="agreement"], input[name="agreement"], input[name="Agreement"]'
      )
      .prop('checked'),
    discipline_id: disciplineId,

    email,

    // grade_index: gradeIndex, // устарело, следует использовать grade_id
    grade_id: GRADE_INDEX_ID_MAP[gradeIndex],

    name: $form.find('input[data-name="Name"], input[data-name="name"], input[data-name="Firstname"]').val(),

    parent_phone: $form.find('input[data-name="Parent-phone"], input[data-name="parent-phone"]').val(),
    phone_number: $form.find('input[data-name="Phone"], input[data-name="phone"]').val(),
    product_id: Number($form.attr('product-id')) || Number($form.attr('product_id')) || undefined,
    product_type: $form.attr('product-type') || $form.attr('product_type'),

    referrer: `${window.location.origin}${window.location.pathname}`,
    source: $form.attr('source'),

    subscription_external: $form.attr('subscription_external') || $form.attr('subscription-external'),
    subscription_externship: subscriptionExternship ? subscriptionExternship === 'true' : subscriptionExternship,
    subscription_media: $form.attr('subscription_media') || $form.attr('subscription-media'),
    user_tag_ids: Number($form.attr('tag-ids')) || Number($form.attr('tag_ids')) || undefined,
    user_type: $form.attr('user_type') || $form.attr('user-type'),
    communication_method: $form
      .find('select[data-name="Communication-method"], select[data-name="communication-method"]')
      .val(),
  }
}

/**
 * Создаёт дополнительные эффекты интерфейса при отправке формы.
 * @param {JQuery} $form Форма.
 */
const createAdditionalEffects = ($form: JQuery) => {
  const context = $form.attr('context')
  const contextField = $('.context-field')

  if (context && contextField.length) {
    contextField.val(context)
  }

  const phoneNumber = $form.find('input[data-name="Phone"], input[data-name="phone"]').val()
  const $formPhoneNumberField = $form.find('[name="phoneNumber"], [name="PhoneNumber"]')

  if ($formPhoneNumberField.length) {
    $formPhoneNumberField.val(phoneNumber)
  }
}

/**
 * Стандартный обработчик отправки формы.
 * Работает по принципу "если атрибут есть, то функционал включается, иначе ничего не происходит".
 * @param {Event} event Submit-событие отправки формы.
 * @param {LeadRequestData} [lrData] Объект лид-ревеста.
 */
export const submitForm = async (
  event: Event,
  lrData?: LeadRequestData,
  lrContext?: string
): Promise<LeadRequestResponse> => {
  event.preventDefault()
  event.stopPropagation()

  const $form = $(event.currentTarget)

  if ($form.length !== 1) {
    return Promise.reject()
  }

  const formSubmitBtn = $form.find('input[type="submit"]')
  if (formSubmitBtn.hasClass('disabled')) {
    return Promise.reject()
  }

  formSubmitBtn.addClass('disabled')

  createAdditionalEffects($form)

  const context = $form.attr('context') || lrContext || ''
  const analyticTrigger = $form.attr('analytic-trigger')
  const [action, label] = $form.attr('push-event')?.split('#') || []

  try {
    const data = await sendLeadRequest({
      analyticTrigger,
      context,
      lrData: lrData || getLeadRequestData(event),
      pushEvent: [action, label],
    })

    const verificationToken = $('[name="verification_token"]').val()
    if (verificationToken) {
      await FoxfordService.api.post(`/api/lead_requests/${data.response.id}/phone_confirmations`, {
        phone_number: $form.find('input[data-name="Phone"], input[data-name="phone"]').val(),
        verification_token: verificationToken,
      })
    }

    $form.hide()
    $form.siblings('.w-form-done').show()

    formSubmitBtn.removeClass('disabled')

    return data
  } catch (error) {
    leadRequestErrorHandler($form, error?.errors)

    return Promise.reject(error)
  }
}

/**
 * Отключает счётчик отправленных форм в Webflow.
 * @param {Event} event Submit-событие отправки формы.
 * @param {Function} submitFormCustomFunction Функция, которая будет вызвана после отправки формы (если не передана, то будет вызвана стандартная функция "submitForm").
 * @param {LeadRequestData} lrData Объект LeadRequestData.
 */
export const submitFormWithoutWebflowNotice = <T = LeadRequestResponse>(
  event: Event,
  submitFormCustomFunction?: (event: Event, lrData?: LeadRequestData) => Promise<T>,
  lrData?: LeadRequestData
): Promise<LeadRequestResponse | T> => {
  event.stopPropagation() // останавливает всплытие
  event.preventDefault() // останавливает обновление страницы

  return submitFormCustomFunction ? submitFormCustomFunction(event, lrData) : submitForm(event, lrData)
}
