// @flow

import $ from 'jquery'

/**
 * Функция для отображения уведомлений
 * @param {string} text - текст уведомления
 * @param {number} time - через какое время скрывать
 */
export const noteNotification = (text?: string, time: number = 2500) => {
  const note = $('[data-note]')
  note.css('top', '0px')

  setTimeout(() => {
    note.css('top', '-140px')
  }, time)

  // Меняем текст в notification
  if (text !== undefined) {
    note.text(text)
  }
}
