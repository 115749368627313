// @flow

/**
 * Делаем правильное склонение слов.
 * Например слово "занятие", передаем массив ['занятие', 'занятий', 'занятия']
 * И получаем 1 - занятие ... 10 занятий ... 3 занятия
 * @param { number } num - нужное число
 * @param { [string] } words - массив с 3 вариантами склонений.
 * @returns string
 */
export const declensionNum = (num: number, words: string[]): string =>
  words[num % 100 > 4 && num % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][num % 10 < 5 ? num % 10 : 5]]
