// @flow

type fnOnClose = () => void
type fnOnOpen = (e?: Event) => void

type ModalSettings = {
  onOpen?: Array<fnOnOpen>,
  onClose?: Array<fnOnClose>,
  open?: (modalWindow: ModalWindow) => void,
}

type Modals = { [key: string]: ModalSettings, fixedClasses?: string[] }

export class ModalWindow {
  #modalsWrapper: HTMLElement
  #modalSettings: Modals | null
  #body: HTMLBodyElement
  #clickHandler: (e: MouseEvent) => void
  #keyPushHandler: (e: KeyboardEvent) => void
  #fixBlocks: string[]

  isOpen: boolean = false

  constructor(settings?: Modals) {
    const modalsWrapper = document.querySelector('[data-modals]')

    if (!(modalsWrapper || modalsWrapper instanceof HTMLDivElement) || !document.body) {
      return
    }

    this.#modalsWrapper = modalsWrapper
    this.#modalsWrapper.style.display = 'none'
    this.#modalSettings = settings || null
    this.#body = document.body
    this.#fixBlocks = settings?.fixedClasses || []

    for (const modal of modalsWrapper.children) {
      const modalName = modal.getAttribute('data-modal')

      if (!modalName) {
        continue
      }

      this.#initModal(modal)

      document.querySelectorAll(`[data-modal-open=${modalName}]`).forEach((button) => {
        if (button.hasAttribute('data-is-listener-set')) {
          return
        }

        button.addEventListener('click', (event: Event) => {
          this.open(modalName, event)
        })

        button.setAttribute('data-is-listener-set', '')
      })

      if (this.#modalSettings?.[modalName]?.open) {
        this.#modalSettings[modalName].open(this)
      }
    }

    this.#clickHandler = (event) => {
      const target = event.target

      if (target instanceof HTMLElement && target.closest('[data-modal-close]')) {
        this.close()
      }
    }

    this.#keyPushHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (this.isOpen) {
          this.close()
        }
      }
    }
  }

  open(modalName: string, event?: Event) {
    const modalNode = document.querySelector(`[data-modal=${modalName}]`)

    if (!modalNode) {
      return
    }

    modalNode.style.display = 'flex'
    modalNode.setAttribute('data-modal-is-open', '')
    modalNode.classList.add('active')

    this.#modalsWrapper.addEventListener('click', this.#clickHandler)
    window.addEventListener('keydown', this.#keyPushHandler)

    setTimeout(() => {
      modalNode.style.opacity = '1'
    }, 50)

    if (!this.isOpen) {
      this.#modalsWrapper.style.display = 'flex'
      this.#modalsWrapper.style.position = 'fixed'
      this.#modalsWrapper.style.left = '0%'
      this.#modalsWrapper.style.top = '0%'
      this.#modalsWrapper.style.right = '0%'
      this.#modalsWrapper.style.bottom = '0%'
      this.#modalsWrapper.style.overflow = 'auto'
      this.#modalsWrapper.style.maxHeight = '100vh'
    }

    if (this.#modalSettings && this.#modalSettings[modalName]?.onOpen) {
      this.#execFunctions(event, this.#modalSettings[modalName].onOpen)
    }

    this.isOpen = true
    this.#disableScroll()
  }

  close() {
    let modalName: string

    this.#modalsWrapper.removeEventListener('click', this.#clickHandler)
    window.removeEventListener('keydown', this.#keyPushHandler)

    for (const modal of this.#modalsWrapper.children) {
      modal.style.opacity = '0'
      modal.classList.remove('active')

      if (modal.hasAttribute('data-modal-is-open')) {
        this.#modalsWrapper.scrollTo(0, 0)
        modalName = modal.getAttribute('data-modal') || ''

        setTimeout(() => {
          modal.style.display = 'none'
          this.#modalsWrapper.style.display = 'none'

          this.#enableScroll()
          modal.removeAttribute('data-modal-is-open')
        }, 300)
      } else {
        modal.style.display = 'none'
      }
    }

    this.isOpen = false

    if (modalName && this.#modalSettings && this?.#modalSettings?.[modalName]?.onClose) {
      this.#execFunctions(undefined, this.#modalSettings[modalName].onClose)
    }
  }

  #initModal(modal: HTMLElement) {
    modal.style.display = 'none'
    modal.style.opacity = '0'
  }

  #execFunctions(event?: Event, fns?: ((e?: Event) => void)[]) {
    if (fns) {
      fns.forEach((fn) => {
        fn(event)
      })
    }
  }

  #disableScroll() {
    const paddingOffset = `${window.innerWidth - this.#body.offsetWidth}px`
    const pagePosition = window.scrollY

    this.#lockPadding()

    this.#body.style.paddingRight = paddingOffset
    this.#body.classList.add('disable-scroll')
    this.#body.dataset.position = pagePosition
    this.#body.style.top = `${-pagePosition}px`
  }

  #enableScroll() {
    const pagePosition = parseInt(this.#body.dataset.position, 10)

    this.#unlockPadding()

    this.#body.style.top = 'auto'
    this.#body.classList.remove('disable-scroll')
    this.#body.removeAttribute('data-position')
    this.#body.style.paddingRight = '0px'
    window.scroll({ top: pagePosition, left: 0 })
  }

  #lockPadding() {
    const paddingOffset = window.innerWidth - this.#body.offsetWidth
    const fixedNodes = this.#getNodesWithPositionFixed()

    this.#setPaddingForFixedElements(fixedNodes, paddingOffset)

    this.#setOffsetPaddingForHeaderModule(paddingOffset)
  }

  #unlockPadding() {
    const fixedNodes = this.#getNodesWithPositionFixed()

    this.#setPaddingForFixedElements(fixedNodes, 0)

    this.#setOffsetPaddingForHeaderModule(0)
  }

  #setPaddingForFixedElements(elements: Array<HTMLElement>, paddingOffset: number) {
    elements.forEach((element) => {
      element.style.paddingRight = `${paddingOffset}px`
    })
  }

  #getNodesWithPositionFixed(): Array<HTMLElement> {
    const fixedNodes: HTMLElement[] = [...document.querySelectorAll('[data-position-fix]')]

    if (this.#fixBlocks.length !== 0) {
      this.#fixBlocks.forEach((queryString) => {
        const node = document.querySelector(queryString.includes('.') ? queryString : `.${queryString}`)

        if (node) {
          fixedNodes.push(node)
        }
      })
    }

    return fixedNodes
  }

  #setOffsetPaddingForHeaderModule(offset: number) {
    const header = document.querySelector('#header')
    const foxNav = header?.parentElement

    if (foxNav && foxNav instanceof HTMLElement) {
      const position = window.getComputedStyle(foxNav).position
      if (position === 'fixed') {
        foxNav.style.paddingRight = `${offset}px`
      }
    }
  }
}
