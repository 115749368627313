// @flow

import { Foxford, CaptchaError, Analytics } from '@foxford/foxford-js-sdk'

import { addAnalytic, addAppOptionsEvent } from 'common/analytics'
import { FASTDEV_USER_EVENTS } from 'services/user/data'
import { Storage } from 'utils/storage'

import { UserService } from '../user'

import { BackgroundWorker } from './helpers/background-worker'
import { deleteCookie } from './helpers/migrate-uid'

import type {
  AppOptions,
  Experiment,
  FoxfordWidgetsConfig,
  IPlugin,
  Widget,
  LeadRequestData,
  LeadRequestResponse,
} from '@foxford/foxford-js-sdk'

interface FoxfordInterface {
  api: $PropertyType<Foxford, 'api'>;
  applyPlugin(plugin: IPlugin): void;
  user: UserService;
  promo: $PropertyType<Foxford, 'promo'>;
  leadrequest: $PropertyType<Foxford, 'leadrequest'>;
  course: $PropertyType<Foxford, 'course'>;
  productPack: $PropertyType<Foxford, 'productPack'>;
  tag: $PropertyType<Foxford, 'tag'>;
  cart: $PropertyType<Foxford, 'cart'>;
  widgets: $PropertyType<Foxford, 'widgets'>;
  getInstance: () => Foxford;
  +appOptions: ?AppOptions;
  +events: typeof Foxford.events;
  +getExperimentValue: (experimentId: string) => string | null;
  +pushExperiment: (
    name: $PropertyType<Experiment, 'name'>,
    value: $PropertyType<Experiment, 'value'>
  ) => Promise<void>;
}

type AppExperiment = {
  ...Experiment,
  resolve?: () => void,
  reject?: () => void,
}

if (process.env.NODE_ENV === 'production') {
  Analytics.initGlobal()
}

class FoxfordService implements FoxfordInterface {
  #foxford: Foxford
  api: $PropertyType<Foxford, 'api'>
  user: UserService
  promo: $PropertyType<Foxford, 'promo'>
  leadrequest: $PropertyType<Foxford, 'leadrequest'>
  course: $PropertyType<Foxford, 'course'>
  productPack: $PropertyType<Foxford, 'productPack'>
  tag: $PropertyType<Foxford, 'tag'>
  cart: $PropertyType<Foxford, 'cart'>
  widgets: $PropertyType<Foxford, 'widgets'>
  events: typeof Foxford.events = Foxford.events
  appOptions: ?AppOptions
  experimentsWorker: BackgroundWorker<AppExperiment>

  constructor() {
    const stage = process.env.NODE_ENV === 'development' && !window.location.origin.match('.wf')
    const betaWidgetsProject = window.location.origin.includes('beta-widgets.') // специальный ленд, где всегда будут бета виджеты
    const HOST = stage ? window.location.origin : 'https://foxford.ru'
    const self = this
    this.#foxford = new Foxford({
      widgetsConfig: {
        version: betaWidgetsProject ? 'beta' : 'latest',
      },

      antibotConfig: {
        antibotOnload: () => {
          // eslint-disable-next-line no-console
          console.debug('Antibot is loaded', window.antibotInitialized)
        },
      },
      api: {
        config: {
          baseURL: HOST,
        },
      },
      captchaConfig: () => ({
        locale: 'ru',
        notify: () => {},
        onErrorChallenge: (provider, error: CaptchaError) => {
          // eslint-disable-next-line no-console
          console.error(provider, error)
        },
        onRenderCaptcha: (_provider) => {},
        onSuccessChallenge: (_provider) => {},
        onWarnChallenge: (provider, error: CaptchaError) => {
          // eslint-disable-next-line no-console
          console.warn(provider, error)
        },
        provider: self.appOptions?.captcha?.provider,
        sitekey: self.appOptions?.captcha?.siteKey,
      }),
      host: HOST,
    })

    this.api = this.#foxford.foxApi
    this.user = new UserService(this.fetchAppOptions, this.api, this.#foxford.appOptions)
    this.promo = this.#foxford.promo
    this.leadrequest = this.#foxford.leadrequest
    this.course = this.#foxford.course
    this.productPack = this.#foxford.productPack
    this.tag = this.#foxford.tag
    this.cart = this.#foxford.cart
    this.widgets = this.#foxford.widgets

    this.experimentsWorker = new BackgroundWorker<AppExperiment>()
    // eslint-disable-next-line no-underscore-dangle
    this.experimentsWorker.run(this._experimentHandler)

    if (window.location.hostname.endsWith('foxford.ru')) {
      window.addEventListener(
        FASTDEV_USER_EVENTS.FETCH_USER,
        () => {
          deleteCookie('uid', '/', '.foxford.ru')
        },
        { once: true }
      )
    }
  }

  applyPlugin(plugin: IPlugin) {
    this.#foxford.applyPlugin(plugin)
  }

  getInstance: () => Foxford = () => this.#foxford

  fetchAppOptions: () => Promise<AppOptions> = async () => {
    const appOptions = await this.#foxford.appOptions.getAppOptions()

    this.user.initializeUser(appOptions.user)

    addAppOptionsEvent()

    this.appOptions = appOptions

    // TODO временное решение, чтобы у нас в ФастДеве можно было делать логику после получения appOptions
    window.dispatchEvent(new CustomEvent(FASTDEV_USER_EVENTS.FETCH_USER, { detail: this.user }))

    return appOptions
  }

  async initWidgets(config: FoxfordWidgetsConfig): Promise<void> {
    await this.widgets.create(config)
    this.fetchAppOptions()
  }

  updateWidget(name: string, config: $Shape<Widget>): void {
    this.widgets.update(name, config)
  }

  // eslint-disable-next-line require-await
  pushExperiment: (
    name: $PropertyType<Experiment, 'name'>,
    value: null | $PropertyType<Experiment, 'value'>
  ) => Promise<void> = async (name, value) => {
    if (value === null) {
      return
    }

    return new Promise((resolve, reject) => {
      const exp: AppExperiment = { name, value, resolve, reject }

      if (this.appOptions) {
        this.experimentsWorker.add(exp)
      } else {
        window.addEventListener(
          FASTDEV_USER_EVENTS.SET_USER,
          () => {
            this.experimentsWorker.add(exp)
          },
          { once: true }
        )
      }
    })
  }

  /**
   * Returns a value of an AB-test experiment selected by it's id
   */
  getExperimentValue: (experimentName: $PropertyType<Experiment, 'name'>) => null | string = (experimentName) => {
    const { experiments = [] } = this.appOptions || {}
    const experiment = experiments.find(({ name }) => name === experimentName)

    return experiment?.value || null
  }

  // eslint-disable-next-line complexity
  _experimentHandler: (experiment: AppExperiment) => Promise<void> = async (
    experiment: AppExperiment
  ): Promise<void> => {
    const uid: string | null = this.appOptions?.userIdentity || null

    if (typeof uid !== 'string') {
      if (typeof experiment.reject === 'function') {
        experiment.reject()
      }
      throw new TypeError('Uid is empty')
    }

    const experiments: { [key: string]: string[] } = Storage.getJson('seenExperiments') ?? {}
    const uidExperiments = experiments[`${uid}`] || []
    const experimentKey = `${experiment.name}:${experiment.value}`

    if (uidExperiments.includes(experimentKey)) {
      // eslint-disable-next-line max-depth
      if (typeof experiment.reject === 'function') {
        experiment.reject()
      }
      return
    }

    try {
      await this.user.pushEvent({ action: 'experiment', label: experiment.name })
      Storage.setJson('seenExperiments', { ...experiments, [uid]: [...uidExperiments, experimentKey] })

      addAnalytic('external.experiment.show', { experiment })
      //для фиксации события в аналитике (с)Роман
      setTimeout(() => {
        if (typeof experiment.resolve === 'function') {
          experiment.resolve()
        }
      }, 100)
    } catch (error) {
      Storage.setJson('seenExperiments', {
        ...experiments,
        [`${uid}`]: uidExperiments.filter((item: string): boolean => item !== experimentKey),
      })
      // eslint-disable-next-line no-console
      console.error("Couldn't push user event with experiment id", {
        error: error instanceof Error ? error.toString() : error,
        experiment,
        uid,
      })

      if (typeof experiment.reject === 'function') {
        experiment.reject()
      }
    }
  }

  sendLeadRequest: (leadRequest: LeadRequestData, urlGetParams?: string) => Promise<LeadRequestResponse> = (
    leadRequest
  ) => {
    addAnalytic('app.user.lead_request', leadRequest)

    return this.leadrequest.send(leadRequest)
  }
}

const fxfService: FoxfordService = new FoxfordService()

export { fxfService as FoxfordService }
