// @flow

import $ from 'jquery'

import { declensionNum } from './declension-num'

const $title = $('[data-countdown="title"]')

const $days = $('[data-countdown="days"]')
const $hours = $('[data-countdown="hours"]')
const $minutes = $('[data-countdown="minutes"]')

const $daysText = $('[data-countdown="days-text"]')
const $hoursText = $('[data-countdown="hours-text"]')
const $minutesText = $('[data-countdown="minutes-text"]')

// $FlowFixMe
export const getTimeRemaining = (deadline: string) => {
  const diff = new Date(deadline) - new Date()

  if (diff <= 0) {
    return {
      days: '0',
      daysText: 'дней',
      hours: '0',
      hoursText: 'часов',
      minutes: '0',
      minutesText: 'минут',
      diff: diff,
    }
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const daysText = declensionNum(days, ['день', 'дня', 'дней'])

  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
  const hoursText = declensionNum(hours, ['час', 'часа', 'часов'])

  const minutes = Math.floor((diff / 1000 / 60) % 60)
  const minutesText = declensionNum(minutes, ['минута', 'минуты', 'минут'])

  const dataClock = {
    days: days,
    daysText: daysText,
    hours: hours,
    hoursText: hoursText,
    minutes: minutes,
    minutesText: minutesText,
    diff: diff,
  }

  return dataClock
}

/**
 * Таймер обратного отсчета.
 */
export const countdownTimer = (deadline: string) => {
  const diff = new Date(deadline) - new Date()

  if (diff <= 0) {
    $days.text('0')
    $daysText.text('дней')
    $hours.text('0')
    $hoursText.text('часов')
    $minutes.text('0')
    $minutesText.text('минут')

    $title.text('Ой, а акция закончилась')

    return
  }

  const updacountdownTimer = () => {
    setInterval(updacountdownTimer, 60000) // вызываем раз в 1 минуту

    const { days, daysText, hours, hoursText, minutes, minutesText } = getTimeRemaining(deadline)

    $days.text(String(days))
    $daysText.text(daysText)
    $hours.text(`0${hours}`.slice(-2))
    $hoursText.text(hoursText)
    $minutes.text(`0${minutes}`.slice(-2))
    $minutesText.text(minutesText)
  }

  updacountdownTimer()
}
