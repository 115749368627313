// @flow

import type { User } from '@foxford/foxford-js-sdk'

type Payload = { ... }

const TRACK_ANALYTICS_DATA: string = 'analytics/TRACK_ANALYTICS_DATA'
const TRACK_ANALYTICS_DATA_V2: string = 'analytics/TRACK_ANALYTICS_DATA_V2'

/**
 * [0] eventCategory
 * [1] eventType
 * [2] eventValue
 * [3] eventLabel
 * [4] eventDataObject
 * 'eventCategory#eventType#eventValue#eventLabel#eventDataObject'
 * @param {string} trigger
 * @returns
 */
export const addAnalyticV2 = (trigger: string): boolean => {
  if (!trigger || typeof trigger !== 'string') {
    throw new TypeError('Attention!!! Check your call of FireAnalytics function. Event category is invalid.')
  }

  const args = trigger.split('#')
  const [eventCategory] = args

  let eventType
  let eventValue
  let eventLabel
  let eventDataObject

  if (args.length === 2) {
    eventType = args[1]
  }

  if (args.length === 3) {
    eventType = args[1]
    eventValue = args[2]
  }

  if (args.length === 4) {
    eventType = args[1]
    eventValue = args[2]
    eventLabel = args[3]
  }

  if (args.length === 5) {
    eventType = args[1]
    eventValue = args[2]
    eventLabel = args[3]
    eventDataObject = args[4]
  }

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({ event: null, payload: null })

  window.dataLayer?.push({
    event: TRACK_ANALYTICS_DATA_V2,
    payload: {
      eventCategory,
      eventDataObject,
      eventLabel,
      eventType,
      eventValue,
    },
  })

  return true
}

// Старая версия, V1
export const addAnalytic = (trigger: string, payload?: ?Payload = null): boolean => {
  if (!trigger || typeof trigger !== 'string') {
    console.warn('Attention!!! Check your call of trackAnalytics function. Trigger is invalid.')

    return false
  }

  const data = payload || {}

  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_BETA === 'true') {
    /* eslint-disable */
    console.debug(
      `%c 📊Track analytic action: ` + `%c${trigger}`,
      'background: linear-gradient(to top, #4b79a1, #283e51); padding: 8px; font-size: 12px; color: #ffffff;',
      'background: linear-gradient(to top, #4b79a1, #283e51); padding: 8px; font-size: 12px; color: #ffffff; font-weight: bold;'
    )

    if (Object.keys(data).length > 0) {
      console.debug(`%c ${JSON.stringify(data, null, 2)}`, 'font-size: 10px; color: #333333; width: 100%;')
    }
    /* eslint-enable */
  }

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: null,
    payload: null,
  })

  window.dataLayer.push({
    event: TRACK_ANALYTICS_DATA,
    payload: {
      properties: data,
      trigger: trigger,
    },
  })

  return true
}

/**
 * Проставляет событие аналитики о том, что новый пользователь зарегистрировался в фоксе
 */
export const addRegistrationAnalytics = async (getUser: () => Promise<User>) => {
  // нужно так же как в
  // https://github.com/foxford/stoege
  // client/src/features/auth/model/events/auth.js
  // redirectAfterRegistrationWithUser.watch
  const user: User = await getUser() // сделано так из-за цикличной зависимости этого модуля и src/services/foxford/index.js

  // нужно так же как в
  // https://github.com/foxford/stoege
  // client/src/features/auth/model/events/auth.js
  // redirectAfterRegistrationWithUser.watch
  addAnalytic('app.user.registration', {
    gradeId: user.grade.id,
    gradeIndex: user.grade.index,
    id: user.id,
    label: user.type,
    type: user.type,
  })
}

// Отправляет в dataLayer событие инициализации app_options на внешних лендах
export const addAppOptionsEvent = (): void => {
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer.push({
      event: 'external/APP_OPTIONS_INITIALIZED',
      payload: null,
    })
  }
}
