// @flow

import { CookieStorage } from 'local-storage-fallback'

import { JSONMixin } from './JSONMixin'

class CookieStorageWithExpiration extends CookieStorage {
  cookieOptions: { ... } = {}

  setJson: typeof JSONMixin.setJson

  getJson: typeof JSONMixin.getJson

  constructor() {
    super()
    Object.assign(this, JSONMixin)
  }

  setItem(key: string, value: string, expiration: number = 0): string {
    this.cookieOptions = { ...this.cookieOptions, maxAge: expiration }
    super.setItem(key, value)

    return value
  }
}

export { CookieStorageWithExpiration }
