// @flow

import $ from 'jquery'

import { BANNER_STATUS } from 'constants/foxford'

import { setLocalItem } from './local-item-actions'

export const banner = {
  show: () => {
    $('[data-banner="true"]').removeClass('hidden')
  },

  hide: () => {
    setLocalItem(BANNER_STATUS, 'hide')
    $('[data-banner="true"]').addClass('hidden')
  },
}
