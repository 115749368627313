// @flow

export const HOST = 'https://foxford.ru'

export const HOST_FILES = 'https://s.foxford.ngcdn.ru'

export const FOOTER_CONTAINER_NAME = 'FoxFooter'

export const HEADER_CONTAINER_NAME = 'FoxHeader'

export const LEAD_REQUEST_TYPE = 'LeadRequests::External'

export const BANNER_STATUS = 'fox-banner'

// https://foxford.ru/api/grades
export const GRADE_INDEX_ID_MAP = {
  '10': 5,
  '0': 17,
  '11': 6,
  '1': 14,
  '2': 15,
  '3': 16,
  '4': 12,
  '5': 13,
  '6': 1,
  '7': 2,
  '8': 3,
  '9': 4,
}
