// @flow

export const loadScript = (url: string, async: boolean = true): Promise<{ status: boolean, ... }> =>
  new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script')
      script.src = url
      script.type = 'text/javascript'
      script.async = async

      script.addEventListener('load', () => {
        resolve({ status: true })
      })

      script.addEventListener('error', () => {
        reject({
          status: false,
          message: `Ошибка при загрузке скрипта ${url}`,
        })
      })

      document.body?.appendChild(script)
    } catch (error) {
      reject(error)
    }
  })
