/* eslint-disable prefer-arrow/prefer-arrow-functions */
// @flow

function getCookie(name) {
  return document.cookie.split(';').some((c) => c.trim().startsWith(`${name}=`))
}

export function deleteCookie(name: string, path: string, domain: string) {
  if (getCookie(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''}${
      domain ? `;domain=${domain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`
  }
}
