// @flow

import { App } from 'common/app'
import { createRouter } from 'common/router'

import { ROUTES } from './pages/routes'

const router = createRouter(ROUTES)

const app: App = new App({
  documentReadyListeners: [router.runCurrentPageScript],
})

window.app = app

export { app }
