// @flow

// Универсальная функция, чтобы сохранять данные в локалсторедж
export const setLocalItem = (name: string, data: any) => {
  // Если строка, то без JSON.stringify
  if (typeof data === 'string') {
    localStorage.setItem(name, data)
    return
  }

  localStorage.setItem(name, JSON.stringify(data))
}

/**
 * Универсальная функция для получения, обработки ошибок и парсинга данных из localStorage.
 * Если получаемое значение просто строка, то лучше использовать localStorage.getItem(name)
 * Если же не просто строка, то данной функцией
 * @param {string} name название ключа в sessionStorage
 * @param {any} baseData можем указать: 'false', '[]', '{}'
 */
export const getLocalItem = (name: string, baseData: string = 'false'): any => {
  const localItem = localStorage.getItem(name)

  if (
    localItem === '' ||
    localItem === undefined ||
    localItem === 'undefined' ||
    localItem === null ||
    localItem === 'null'
  ) {
    return JSON.parse(baseData)
  }

  const data = JSON.parse(localItem)
  return data
}

/**
 * Удаление выбраного item из localStorage
 * @param {string} name удаляем выбранный item
 */
export const removeLocalItem = (name: string) => {
  localStorage.removeItem(name)
}

/**
 * Полная отчистка localStorage
 */
export const clearLocalItem = () => {
  localStorage.clear()
}
