// @flow

import { UserService as SDKUserService } from '@foxford/foxford-js-sdk'

import { FASTDEV_USER_EVENTS } from './data'

import type { UserData, User, AppOptions, IAppOptionsService, ApiInstance } from '@foxford/foxford-js-sdk'

const events = FASTDEV_USER_EVENTS

export interface UserServiceI {
  +refreshUser: () => Promise<User>;
  +getCurrentUser: () => User;
  +initializeUser: (user: UserData) => void;
  events: typeof events;
}

export class UserService extends SDKUserService implements UserServiceI {
  #refreshAppOptions: () => Promise<AppOptions>
  api: ApiInstance
  events: typeof events = events
  user: User

  constructor(refreshAppOptions: () => Promise<AppOptions>, apiInstance: ApiInstance, appOptions: IAppOptionsService) {
    super(apiInstance, appOptions)
    this.api = apiInstance
    this.#refreshAppOptions = refreshAppOptions
    this.user = SDKUserService.createDefaultUser()
  }

  /**
   * Конструирует пользователя из данных и устанавливает его в приложение (app_options)
   * @param {UserData} userData - данные пользователя
   */
  initializeUser: (userData: ?UserData) => void = (userData) => {
    if (!userData) {
      return
    }

    // Обнуляем фейковый email адрес (с доменом @my.foxford.ru), чтобы не использовать его в формах
    if (userData.fakeEmail) {
      userData.email = ''
    }

    // Создаем юзера по умолчанию
    const user: User = SDKUserService.createUser(userData)
    this.#setUser(user)
  }

  /**
   * Устанавливает пользователя в приложение и оповещает об этом
   * @param {User} user - пользователь который будет установлен в приложение
   */
  #setUser: (user: User) => void = (user) => {
    this.user = user
    window.dispatchEvent(new CustomEvent(this.events.SET_USER, { detail: user }))

    window.dataLayer.push({
      event: 'external/SET_USER',
      payload: {
        user,
      },
    })
  }

  /**
   * Получение текущего пользователя приложения
   * @returns User
   */
  getCurrentUser: () => User = () => this.user

  /**
   * Обновляет пользователя в приложении запрашивая актуальные данные на бэкенде
   * @returns Promise<User>
   */
  refreshUser: () => Promise<User> = async () => {
    const appOptions = await this.#refreshAppOptions()
    this.initializeUser(appOptions.user)
    return this.getUser()
  }
}
