// @flow

import { pathToRegexp } from 'path-to-regexp'

import type { OnUserSetCallback } from './listeners/on-user-set'
import type { UserTag } from './tag'
import type { ParseOptions, RegExpOptions } from 'path-to-regexp'

// TODO: вынести все типы в отдельный файл
// TODO: отрефакторить файл, написать комменты jsdoc к функциям и отдельным их частям

const cache = {}
const cacheLimit = 10000
let cacheCount = 0

const compilePath = (path: string, options: RegExpOptions & ParseOptions) => {
  const { end = false, strict = false, sensitive = false } = options
  const cacheKey = `${path}/${end.toString()}/${strict.toString()}/${sensitive.toString()}`
  const pathCache = cache[cacheKey] || (cache[cacheKey] = {})

  if (pathCache[path]) {
    return pathCache[path]
  }

  const keys = []
  const regexp = pathToRegexp(path, keys, options)
  const result = { keys, regexp }

  if (cacheCount < cacheLimit) {
    pathCache[path] = result
    cacheCount += 1
  }

  return result
}

type ComponentData = {
  VISIT_TAG?: UserTag,
  onUserSetCallback?: OnUserSetCallback,
  ...
}

export type MatchedRoute = {
  path: string,
  component?: () => RouteComponent,
  url: string, // the matched portion of the URL
  isExact: boolean,
  params: { ... },
  componentData?: Object,
  ...
}

export type RouteComponent = Promise<{ default: (route: MatchedRoute) => void }>

export type Route = {
  exact: boolean,
  path: string,
  component?: () => RouteComponent,
  componentData?: ComponentData,
}

const matchPath = (pathname, options: Route): MatchedRoute | null => {
  const { path, exact = false, strict = false, sensitive = false } = options

  if (!path && path !== '') {
    return null
  }

  const { regexp, keys } = compilePath(path, {
    end: exact,
    sensitive,
    strict,
  })
  const match = regexp.exec(pathname)

  if (!match) {
    return null
  }

  const [url, ...values] = match
  const isExact = pathname === url
  if (exact && !isExact) {
    return null
  }

  const resultUrl = path === '/' && url === '' ? '/' : url

  return {
    // the path used to match
    component: options.component,

    componentData: options.componentData,

    // the matched portion of the URL
    isExact,
    // whether or not we matched exactly
    params: keys.reduce((memo, key, index) => {
      memo[key.name] = values[index]
      return memo
    }, {}),

    path,

    url: resultUrl,
  }
}

type routerMatchedObject = {
  runCurrentPageScript: () => void,
  pageData?: Object, // componentData, TODO: установить тип ComponentData. Проблема в том, что тип ComponentData - inexact, а в проекте такая возможность выключена
}

export const createRouter = (routes: Array<Route>): routerMatchedObject => {
  const path = window.location.pathname
  const matchedRoute = routes.map((route) => matchPath(path, route)).filter(Boolean)

  if (matchedRoute.length === 0) {
    console.warn('Not found any routes')
    return {
      runCurrentPageScript: () => {},
    }
  } else if (matchedRoute.length > 1) {
    console.warn('Нашлось более 1 подходящего роута для страницы, возьмется первый')
  }

  // TODO: по умолчанию сейчас берется только первый роут, нужно бы сделать так, чтобы использовались все подходящие
  const route = matchedRoute[0]

  return {
    pageData: route.componentData,
    runCurrentPageScript: () => {
      if (route.component !== undefined) {
        route.component().then(({ default: apply }) => apply(route))
      }
    },
  }
}
