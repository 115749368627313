// @flow

/* TODO: По идее значение объекта должно браться из Foxford.events. И это сделать просто.
 * Но дополнительно ситуацию отягощает, что сейчас этот объект импортируется в main,
 * а main должен быть как можно меньше и не иметь зависимостей.
 */
export const FASTDEV_USER_EVENTS = Object.freeze({
  SET_USER: 'set.user',
  FETCH_USER: 'fetch.user',
})
