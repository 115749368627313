// @flow

import $ from 'jquery'

export const changeTabsMarathonIt = ($tab: JQuery) => {
  if ($tab.hasClass('active')) {
    return
  }

  const tabName = $tab.attr('data-tab')

  $('[data-content]').addClass('hide')
  $(`[data-content="${tabName}"]`).removeClass('hide')

  $('[data-tab]').removeClass('active')
  $(`[data-tab="${tabName}"]`).addClass('active')
}
