// @flow

import { MemoryStorage, isSupported } from 'local-storage-fallback'

import { CookieStorageWithExpiration } from './CookieStorageWithExpiration'
import { ExpiredStorage } from './ExpiredStorage'

const getStorage = () => {
  let storage = null

  if (isSupported('localStorage')) {
    // use localStorage
    storage = new ExpiredStorage(window.localStorage)
  } else if (isSupported('cookieStorage')) {
    // use cookies
    storage = new CookieStorageWithExpiration()
  } else if (isSupported('sessionStorage')) {
    // use sessionStorage
    storage = new ExpiredStorage(window.sessionStorage)
  } else {
    // use memory
    storage = new ExpiredStorage(new MemoryStorage())
  }

  return storage
}

const Storage: CookieStorageWithExpiration | ExpiredStorage = getStorage()

export { Storage }
