// @noflow

import $ from 'jquery'

// TODO:
// Функции из этого файла используются в проекте main,
// у которого отдельная сборка, где нет парсера Flow,
// поэтому используется директива @noflow.
// Парсер надо добавить, а директиву изменить.

import './index.css'

export const getSubmitButtons = ($forms) => $forms.find('input[type="submit"]')

export const toggleSubmitButtons = ($forms, isDisabled) => {
  const $buttons = getSubmitButtons($forms)

  $('.foxford-phone-confirm__button-container', '.foxford-phone-confirm').css('display', 'flex')

  // Если номер не подтвержден, то НЕ раздизейбливаем кнопку
  if ($forms.attr('data-prevent-submit') === 'true') {
    return
  }

  if (isDisabled) {
    $buttons.attr('disabled', 'true')
  } else {
    $buttons.removeAttr('disabled')
  }
}
