// @flow

import { EmailAutocomplete } from '@foxford/foxford-js-sdk/plugins/autocomplete-email'
import $ from 'jquery'

import { addAnalytic, addAnalyticV2 } from 'common/analytics'
import { pushYmAnalytics } from 'common/analytics-yandex'
import { banner } from 'common/banner'
import { copyToClipboard } from 'common/copy-to-clipboard'
import { countdownTimer } from 'common/countdown-timer'
import { submitForm } from 'common/form'
import { getSiblings } from 'common/get-siblings'
import { initPhoneMaskModule } from 'common/phone-mask/index'
import { teacherInvite } from 'common/teacher-invite'
import { BANNER_STATUS } from 'constants/foxford'
import { FoxfordService } from 'services/foxford/index'

/**
 * Добавляет автозаполнение полям с [type="email"]
 */
const initEmailAutocompleteModule = () => {
  const $emailInputs = $('input[type="email"]')

  $emailInputs.each((_, input) => {
    FoxfordService.applyPlugin(new EmailAutocomplete(input))
  })
}

/**
 * Добавляет на страницу прослушиватель кликов:
 * - по селектору "[data-trigger]"
 *   (для отправки триггера GTM, контекст указан в атрибуте data-trigger);
 * - по селектору "[data-push-event]"
 *   (для отправки события разметки, контекст указан в атрибуте data-push-event,
 *   Action Name и Label разделяются символом "#", пример: "conversion#apply").
 */
const addAnalyticClickListener = () => {
  $(document).on('click', '[data-trigger]', ({ currentTarget }: Event) => {
    addAnalytic($(currentTarget).attr('data-trigger'))
  })

  $(document).on('click', '[data-trigger-v2]', ({ currentTarget }: Event) => {
    addAnalyticV2($(currentTarget).attr('data-trigger'))
  })

  const userEvent = ['experiment', 'funnel', 'conversion', 'click']

  $('[data-push-event]').on('click', async (event) => {
    const [action, label] = $(event.currentTarget).attr('data-push-event')?.split('#') || []
    const type = $(event.currentTarget).prop('tagName')

    // редирект срабатывает быстрее, чем уходит событие. Для этого добавлена логика ниже
    const href = $(event.currentTarget).attr('href')
    if (type === 'A' && !href.includes('http://?')) {
      event.preventDefault()

      if (userEvent.includes(action)) {
        await FoxfordService.user.pushEvent({ action, label })
        window.location.assign(href)
        return
      }
      console.log(`Указан не допустимый action, ${action}`)
      return
    }

    if (userEvent.includes(action)) {
      FoxfordService.user.pushEvent({ action, label })
    } else {
      console.log(`Указан не допустимый action, ${action}`)
    }
  })

  $(document).on('click', '[data-yandex-event]', ({ currentTarget }: Event) => {
    pushYmAnalytics($(currentTarget).attr('data-yandex-event'))
  })
}

/**
 * Записывает текущую страницу в скрытый инпут по селектору ".input-page".
 */
const setPageHiddenInput = () => {
  const [page] = document.location.href.split('?')

  $('.input-page').val(page)
}

/**
 * Скролл к якорю с отступом сверху, чтобы при скролле, меню не перекрывало часть контента
 */
const scrollToSection = () => {
  $('a[href^="#"]').on('click', ({ currentTarget }) => {
    const href = $(currentTarget).attr('href')

    if (href.length < 2 || href.includes('w-tabs')) {
      return
    }

    const top = window.location.origin.includes('externat') ? $(href).offset().top - 140 : $(href).offset().top - 100

    $('html, body').animate({ scrollTop: top }, 800)

    window.location.hash = href

    return false
  })
}

/**
 * Слушатель для копирования по клику на атрибуты:
 * [data-clipboard="promo"] - промокода
 * [data-clipboard="origin"] - базовый url (window.location.origin)
 * [data-clipboard="origin-pathname"] - базовый url + текущая страница (window.location.origin + window.location.pathname)
 * [data-clipboard="full-url"] - полный url, включая utm метки (window.location.href)
 */
export const addCopyToClipboardListener = () => {
  $('[data-clipboard="promo"]').on('click', ({ currentTarget }) =>
    copyToClipboard($.trim($(currentTarget).text()), 'Промокод скопирован')
  )

  $('[data-clipboard="origin"]').on('click', () => copyToClipboard(window.location.origin, 'Ссылка скопирована'))
  $('[data-clipboard="origin-pathname"]').on('click', () =>
    copyToClipboard(window.location.origin + window.location.pathname, 'Ссылка скопирована')
  )
  $('[data-clipboard="full-url"]').on('click', () => copyToClipboard(window.location.href, 'Ссылка скопирована'))
}

/**
 * Слушатель для инициализации таймера обратного отсчёта
 * Если он на странице есть, то сразу инициализируем.
 * Дату берем из атрибута $('[data-countdown-date]') в формате 'Aug 25 2023 GMT+0300'
 */
const addnItializeTimerListener = () => {
  if ($('[data-countdown="wrapper"]').length > 0) {
    const date = $('[data-countdown-date]').attr('data-countdown-date')
    countdownTimer(date)
  }
}

/**
 * Слушатель для отображения баннера
 * Небольшой костыль для перемещения его над меню
 */
const addBannerListener = () => {
  const interval = setInterval(() => {
    if (window.FoxfordWidgets && $('#foxNav').find('#header').length > 0) {
      clearInterval(interval)

      const $banner = $('[data-banner="true"]')

      if ($banner.length > 0) {
        $('[data-banner-close]').on('click', () => banner.hide())

        const status = localStorage.getItem(BANNER_STATUS)

        if (status === 'hide') {
          banner.hide()
        } else {
          setTimeout(() => {
            const $foxHeader = $('#foxNav').find('#header')
            $banner.insertBefore($foxHeader)
            banner.show()
          }, 500)
        }
      }
    }
  }, 300)
}

/**
 * Слушаетель клика по кнопке "Пригласить учеников", в котором сразу отправляем pushEvent и затем редирект на нужный url
 */
const addTeacherInviteListener = () => {
  $('[data-action="teacher-invite"]').on('click', () => teacherInvite())
}

/**
 * Общий слушатель на открытие и закрытие ответов в разделе FAQ
 */
const addFaqListener = () => {
  document.querySelectorAll('[data-faq="question-wrap"]').forEach((element) => {
    element.addEventListener('click', ({ currentTarget }: Event) => {
      if (!(currentTarget instanceof HTMLElement)) {
        return
      }

      if (currentTarget.classList.contains('open')) {
        currentTarget.classList.remove('open')
        getSiblings(currentTarget).forEach((el) => {
          el.classList.remove('open')
        })
        currentTarget.querySelector(`:scope [data-faq="arrow"]`)?.classList.remove('open')

        return
      }

      currentTarget.classList.add('open')
      getSiblings(currentTarget).forEach((el) => {
        el.classList.add('open')
      })
      currentTarget.querySelector(`:scope [data-faq="arrow"]`)?.classList.add('open')
    })
  })
}

/**
 * Общий слушатель на кнопку "показать ещё", когда нужно раскрыть отзыв (или что-то ещё) полностью
 */
const addShowMoreListener = () => {
  document.querySelectorAll('[data-show-more]').forEach((element) => {
    element.addEventListener('click', ({ currentTarget }: Event) => {
      if (!(currentTarget instanceof HTMLElement)) {
        return
      }

      const openText = currentTarget.getAttribute('data-show-more-open') || 'Показать ещё'
      const closeText = currentTarget.getAttribute('data-show-more-close') || 'Скрыть'

      if (currentTarget.classList.contains('open')) {
        currentTarget.classList.remove('open')
        currentTarget.textContent = openText

        getSiblings(currentTarget).forEach((el) => {
          el.classList.remove('open')
        })

        return
      }

      currentTarget.classList.add('open')
      currentTarget.textContent = closeText

      getSiblings(currentTarget).forEach((el) => {
        el.classList.add('open')
      })
    })
  })
}

/**
 * Функция запускающая самые частоиспользуемые общие функции, но которые при этом почти не загружают страницу
 * и не приносят избыточного функционала. Эту функцию не стоит вызывать нигде кроме app.js
 */
export const lightweihtDocumentReady = () => {
  setPageHiddenInput()
  initEmailAutocompleteModule()
  scrollToSection()

  addAnalyticClickListener()
  addCopyToClipboardListener()
  addnItializeTimerListener()
  addTeacherInviteListener()
  addBannerListener()
  addFaqListener()
  addShowMoreListener()
}

/**
 * Функция запускающая общий часто используемый, но тяжеловесный функционал
 */
export const documentReady = () => {
  $('form').submit((event) => {
    const $form = $(event.currentTarget)

    if ($form.attr('data-prevent-submit') === 'true' || $form.attr('data-stop-submit') === 'true') {
      return
    }

    if ($form.attr('action') === '/search') {
      return
    }

    submitForm(event)
  })

  initPhoneMaskModule()
}
