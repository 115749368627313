// @flow

import $ from 'jquery'

type SliderBreakpoint = {
  slidesInRow: number,
  from: number,
  to: number,
}

export type SliderConfig = {
  selectors: {
    mask: string,
    leftArrow: string,
    rightArrow: string,
    slide: string,
    stateDisable: string,
  },
  remainingSlides: number,
  offset: number,
  adaptiveSlides?: Array<SliderBreakpoint>,
}

// TODO: заменить селекторы по классу на селекторы по data-атрибуту
const SELECTORS = Object.freeze({
  LEFT_ARROW: '.slider__left-arrow',
  MASK: '.custom-slider__mask',
  RIGHT_ARROW: '.slider__right-arrow',
  SLIDE: '.slide',
  STATE_DISABLE: 'state--disable',
})

const DEFAULT_CONFIG: SliderConfig = Object.freeze({
  offset: 20,
  remainingSlides: 2,
  selectors: {
    leftArrow: SELECTORS.LEFT_ARROW,
    mask: SELECTORS.MASK,
    rightArrow: SELECTORS.RIGHT_ARROW,
    slide: SELECTORS.SLIDE,
    stateDisable: SELECTORS.STATE_DISABLE,
  },
})

export class FsdvSlider {
  #config: SliderConfig
  #node: JQuery
  #currentStep: number = 0
  #maxStep: number
  #leftArrowHandler: () => void
  #rightArrowHandler: () => void
  #stepChangeHandler: ?(step?: number) => void

  /**
   * @param {string} node - JQuery селектор контейнера слайдера.
   * @param {SliderConfig} config - Конфиг слайдера.
   * @param {number} config.offset - Отступ слайда. Должен быть равен величине flex-gap контейнера, или margin-right слайда
   * @param {number} config.remainingSlides - Количество слайдов, которые будут оставаться при долистывании.
   * @param {typeof SliderConfig.selectors} config.selectors - JQuery селекторы элементов слайдера
   * @param {Array<SliderBreakpoint>} config.adaptiveSlides - массив брейкпойнтов и количества слайдов. В пределах указанных брейкпойнтов слайды будут иметь адаптивную ширину.
   */

  constructor(node: string, config?: $Shape<SliderConfig> = DEFAULT_CONFIG) {
    const $node = $(node)
    if ($node.length === 0) {
      console.warn(`Cannot create slider with provided selector: ${node}`)
      return
    }

    this.#config = { ...DEFAULT_CONFIG, ...config }
    const { leftArrow, stateDisable } = this.#config.selectors
    $(leftArrow).addClass(stateDisable)

    this.#node = $node
    this.#setMaxStep()

    const rightArrowHandler = () => this.stepTo(1)
    const leftArrowHandler = () => this.stepTo(-1)
    this.#rightArrowHandler = rightArrowHandler
    this.#leftArrowHandler = leftArrowHandler

    this.addArrowListeners()

    if (this.#config.adaptiveSlides) {
      this.setAdaptive(this.#config.adaptiveSlides)
    }
  }

  #calculateSlideWidth(): number {
    return this.#node.find(this.#config.selectors.slide).width() + this.#config.offset
  }

  #updateArrowsState(): void {
    const { leftArrow, rightArrow, stateDisable } = this.#config.selectors
    const $leftArrow = this.#node.find(leftArrow)
    const $rightArrow = this.#node.find(rightArrow)

    if (this.#currentStep === this.#maxStep) {
      $rightArrow.addClass(stateDisable)
    } else if (this.#currentStep === 0) {
      $leftArrow.addClass(stateDisable)
    } else {
      $leftArrow.removeClass(stateDisable)
      $rightArrow.removeClass(stateDisable)
    }
  }

  #setMaxStep() {
    const { selectors, remainingSlides } = this.#config
    this.#maxStep = this.#node.find(selectors.slide).length - remainingSlides
  }

  /**
   * Делает слайды адаптивными на указанных брейкпойнтах. По умолчанию срабатывает только один раз при вызове.
   * При необходимости можно вызывать метод через $(window).resize()
   * @param {Array<SliderBreakpoint>} breakpoints - массив брейкпойнтов и количества слайдов.
   */
  setAdaptive(breakpoints: Array<SliderBreakpoint>) {
    if (!this.#config.adaptiveSlides) {
      this.#config.adaptiveSlides = breakpoints
    }

    breakpoints.forEach((el) => {
      const { from, to, slidesInRow } = el
      const calculatedWidth = (this.#node.width() - this.#config.offset * (slidesInRow - 1)) / slidesInRow
      if (window.innerWidth >= from && window.innerWidth <= to) {
        this.#node.find(this.#config.selectors.slide).width(calculatedWidth)
      }
    })
  }

  /**
   * Возвращает текущий шаг слайдера.
   */
  getCurrentStep(): number {
    return this.#currentStep
  }

  /**
   * Добавляет обработчики на стрелки слайдера. Изначально вызывается в конструкторе класса.
   */
  addArrowListeners(): void {
    const { leftArrow, rightArrow } = this.#config.selectors
    const $node = this.#node

    $node.find(rightArrow).on('click', this.#rightArrowHandler)
    $node.find(leftArrow).on('click', this.#leftArrowHandler)
  }

  /**
   * Убирает обработчики со стрелок.
   */
  removeArrowListeners(): void {
    const { leftArrow, rightArrow } = this.#config.selectors
    const $node = this.#node

    $node.find(rightArrow).off('click', this.#rightArrowHandler)
    $node.find(leftArrow).off('click', this.#leftArrowHandler)
  }

  /**
   * Смена позиции слайдов. Стрелки вызывают этот метод с шагом 1 или -1.
   * @param {number} stepDiff - шаг слайдера.
   */
  stepTo(stepDiff: number): void {
    this.#setMaxStep()
    const stepIsExceeded = stepDiff + this.#currentStep > this.#maxStep || stepDiff + this.#currentStep < 0
    if (stepIsExceeded) {
      return
    }

    const sliderWidth = this.#calculateSlideWidth()
    this.#currentStep += stepDiff
    this.#node
      .find(this.#config.selectors.mask)
      .attr('style', `transform:translateX(-${this.#currentStep * sliderWidth}px)`)

    this.#updateArrowsState()
    this.#stepChangeHandler?.(this.getCurrentStep())
  }

  /**
   * Обработчик, который будет вызывать переданную функцию при смене шага слайдера
   * @param {number} handler - колбэк
   */
  onStepChange(handler: (step?: number) => void) {
    this.#stepChangeHandler = handler
  }
}
