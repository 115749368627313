// @flow

import { ConfirmPhone } from '@foxford/foxford-js-sdk/plugins/confirm-phone'
import $ from 'jquery'

import { FoxfordService } from 'services/foxford/index'

/**
 * После получения юзера, инициализируем плагин подтверждения телефона.
 * Инициализируются только поля с типом "tel" и атрибутом [data-confirm-required="true"]
 * В юбудущем, возможно, уберем проверку на атрибут и оставим на все телефоны с типом tel
 * Документация тут https://github.com/foxford/foxford-js-sdk/tree/STOEGE-20490/src/plugins/confirm-phone
 */
export const initConfirmPhoneAfterSetUser = async () => {
  const user = await FoxfordService.user.getCurrentUser()

  const $phoneInputs = $('input[type="tel"][data-confirm-required="true"]')

  $phoneInputs.each((_, input) => {
    FoxfordService.applyPlugin(
      new ConfirmPhone(input, {
        userData: {
          phone: user.phone ?? '',
          phoneConfirmed: user.phoneConfirmed,
        },
        inputParentsToHide: ['.iti'],
      })
    )
  })
}
