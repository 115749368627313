// @flow

import type { Route, RouteComponent } from 'common/router'

const ROUTER_MAP = Object.freeze({
  HOME: '/',
})

export const ROUTES: Array<Route> = [
  {
    exact: true,
    path: ROUTER_MAP.HOME,
    component: (): RouteComponent => import('./home'),
  },
]
